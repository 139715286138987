"use client"
import React, { useEffect, useRef, useState } from "react";
import { ContextData, storeAssigned, _logoutFunction } from "@/services/Methods/normalMethods";
import { _storeCustomerTypes } from "@/services/Methods/commonPayload";
import localStorageCall from "@/services/Methods/localstorage.hook";
import { _getCountryCodeApi } from "@/services/Redux/Reducer/CustomerSlice";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import withRouteHOC from "@/HOC/withRouterHOC"
import withMiniCartHOC from "@/HOC/withMinCartHOC";
import _ from "lodash";
// import "react-datepicker/dist/react-datepicker.css";
import { getOrders } from "@/services/Redux/Reducer/OrderSlice";
import Header from "@/comman/Header/Header";
import ShoppingCartComponent from "@/comman/ShoppingCartComponent";
import Footer from "@/comman/Footer/Footer";
import useScript from "../../services/Methods/UseScript.hook";
import JoinPopup from "@/comman/JoinPopup";
import * as Sentry from "@sentry/react";
import ButtonComponent from "@/Inputs/ButtonComponent";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import useIpDetection from "../../../hooks/useIpDetection";
import { useRouter } from "next/navigation";


// const STORES = ["us", "ca"];

// const Redirect_STORES = ['us', 'au', 'br', 'bs', 'ca', 'co', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'eu', 'at', 'be', 'bg',
//     'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'jp', 'mx',
//     'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw','tw'];
const STORES = ['us', 'au', 'br', 'bs', 'ca', 'co', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'eu', 'at', 'be', 'bg',
    'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'jp', 'mx',
    'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'tw', 'pf'];

const Template = ({ children, ...props }) => {
    const { pathname, searchParams, params, redirect, isUserLogin, checkoutRef } = props;
    const [showCountries, setShowCountries] = useState(false);
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const [orders, setOrders] = useState([])
    const [selectedStore, setSelectedStore] = useState({ defaultCode: 'us', newCode: 'us' });
    const [userIp, setUserIp] = useState()
    const [storeAssign, setStoreAssign] = useState();
    const router = useRouter();
    const handleOutsideClick = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            event.target !== buttonRef.current
        ) {
            setShowCountries(false);
        }
    };
    useEffect(() => {
        localStorageCall().removeItem('activeStep');
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const MEMBERSHIP_AVAILABLE = _.find(localStorageCall().getItem('normalProducts'), { frequency: "member" }) || null;

    if (!_.includes(pathname, 'main-enrollment') && MEMBERSHIP_AVAILABLE) {
        localStorageCall().removeItem('normalProducts');
    }


    const getData = createSelector(
        state => state.CustomerSlice,
        state => state?.LoginSlice?.alluserData,
        state => state?.PaymentSlice?.paymentScript,
        ({ countryData, isLoading }, userData, paymentScript) => ({
            isLoading,
            countryCodes: _.map(countryData, 'countryCode')?.length > 0 ? _.map(countryData, 'countryCode') : [],
            countyObjects: _.map(countryData, (row) => ({ code: row?.countryCode, name: row?.countryName, currency: row?.currency })),
            userData: userData, paymentScript,
        })
    );

    const { isLoading, countryCodes, countyObjects, paymentScript, userData } = useSelector((state) => getData(state));

    useIpDetection(STORES, params?.store, router, pathname, isUserLogin, setUserIp);

    useScript(paymentScript);

    useEffect(() => { dispatch(_getCountryCodeApi()); }, []);

    useEffect(() => {

        const DEFAULT_STORE = localStorageCall()?.getSingleItem('store');

        if (!DEFAULT_STORE) {
            if (STORES?.includes(params?.store)) {
                localStorageCall().setItem('store', params?.store)
            }
        } else {
            if (DEFAULT_STORE !== params?.store) {
                if (STORES?.includes(params?.store)) {
                    localStorageCall().setItem('store', params?.store);
                    setSelectedStore({ defaultCode: params?.store, newCode: params?.store });
                } else {
                    setSelectedStore({ defaultCode: DEFAULT_STORE, newCode: DEFAULT_STORE });
                }
            } else {
                setSelectedStore({ defaultCode: DEFAULT_STORE, newCode: DEFAULT_STORE });
            }
        }

        const weglotScript = document.createElement('script');
        weglotScript.src = "https://cdn.weglot.com/weglot.min.js";
        weglotScript.async = true;
        weglotScript.onload = () => {
            if (window.Weglot) {
                Weglot.initialize({
                    api_key: 'wg_dcb2ef458c9a4e857e5eefd844a45f9d0',
                });
            }
        };

        document.head.appendChild(weglotScript);

        const zendeskScript = document.createElement('script');
        zendeskScript.id = "ze-snippet";
        zendeskScript.src = "https://static.zdassets.com/ekr/snippet.js?key=c1c6e6aa-6c00-48c7-8f9a-1936ac92a03d";
        zendeskScript.async = true;

        document.head.appendChild(zendeskScript);

        // Cleanup function to remove scripts on unmount
        return () => {
            document.head.removeChild(weglotScript);
            document.head.removeChild(zendeskScript);
        };

    }, [pathname]);


    useEffect(() => {
        if (STORES?.includes(params?.store)) {
            localStorageCall().setItem('store', params?.store)
        }

    }, [pathname]);

    useEffect(() => {
        if (isUserLogin && userData === null) {
            _storeCustomerTypes(dispatch, isUserLogin, searchParams);
        }

        // dispatch(getOrders(null, isUserLogin, (res) => {
        //     setOrders(res?.data)
        // }))
    }, [isUserLogin]);


    const [joinPopup, setJoinPopup] = useState(false);

    if (userData?.emailAddress) {
        Sentry.setUser({ id: userData?.customerId, email: userData?.emailAddress });
        Sentry.setTag("userID", userData?.customerId);
    }

    const [showPopup, setShowPopup] = useState(false);

    const UserCountry = userData?.countryCode;
    const StoreASSigned = storeAssigned();

    const LOCAL_STORAGE_KEY = 'showPopupDismissed';
    useEffect(() => {
        if (localStorageCall().getItem(LOCAL_STORAGE_KEY)) {
            setShowPopup(false);
        }
    }, [setShowPopup]);

    // const handleClose = () => {
    //     localStorageCall().setItem(LOCAL_STORAGE_KEY, 'true');
    //     setShowPopup(false);
    // };
    const Localkey = localStorageCall()?.getItem(LOCAL_STORAGE_KEY);

    const userIpCountry = localStorageCall().getSingleItem('IpCountry');
    console.log("userIpCountry", userIpCountry);
    const storeAssignUppercase = StoreASSigned ? StoreASSigned.toUpperCase() : null;
    // console.log("storeAssignUppercase !== userIpCountry", storeAssignUppercase !== userIpCountry, "keyvalue", !Localkey);

    // useEffect(() => {
    //     if (!Localkey) {
    //         console.log("inside my useEffect 1");
    //         setShowPopup(true);
    //     }
    // }, [userData, userIpCountry]);

    useEffect(() => {
        const storeAssignUppercase = StoreASSigned ? StoreASSigned.toUpperCase() : null;
        setStoreAssign(storeAssignUppercase);

        // if (!searchParams.get('ssokey') && UserCountry && storeAssignUppercase && (storeAssignUppercase !== userIpCountry) && (UserCountry !== storeAssignUppercase) && !Localkey) {
        //     setShowPopup(true);
        // }


    }, [userData, pathname]);


    return (
        <>
            <ContextData.Provider value={{
                showCountries, userData, countryCodes, setShowCountries, countyObjects, dispatch, STORES, handleOutsideClick,
                buttonRef, menuRef, orders, selectedStore, setSelectedStore, checkoutRef, UserCountry, storeAssignUppercase, userIpCountry, Localkey, setShowPopup, showPopup, storeAssign, setStoreAssign, LOCAL_STORAGE_KEY, pathname, ...props
            }
            }>

                <Header  {...{
                    showCountries, userData, countryCodes, setShowCountries, countyObjects, dispatch, STORES, handleOutsideClick, buttonRef,
                    menuRef, joinPopup, setJoinPopup, selectedStore, setSelectedStore,
                    ...props
                }} />

                <div>{children}</div>
                <Footer {...{ joinPopup, setJoinPopup, userData }} />
                <ShoppingCartComponent
                    section={_.includes(pathname, 'distributor') ? 'distributor' : 'normal'}
                    pathname={pathname}
                    _changeFrequencyOfProducts={props?._changeFrequencyOfProducts}
                    _minCartQuantityIncDec={props?._minCartAndViewCartQuantityIncDec}
                    openMiniCart={props?.openMiniCart}
                    setOpenMiniCart={props?.setOpenMiniCart}
                    _deleteProductFromCart={props?._deleteProductFromCart}
                    productData={props?.addToCartProducts?.normalProducts}
                    _autoShipCheck={props?._autoShipCheck} />
            </ContextData.Provider>
            <JoinPopup {...{ joinPopup, setJoinPopup }} />


            {/* <div>
                {showPopup && userIpCountry !== storeAssignUppercase && UserCountry !== storeAssignUppercase && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
                            {
                                isUserLogin && (
                                    <p className="text-gray-800 text-center mb-4">
                                        Your Addressed Country ({UserCountry}) does not match this ({storeAssign}) exist Store .
                                    </p>
                                )
                            }
                            {
                                !isUserLogin && (
                                    <p className="text-gray-800 text-center mb-4">
                                        Your IP ({userIpCountry}) does not match this ({storeAssignUppercase}) exist Store .
                                    </p>
                                )
                            }


                            <p className="text-gray-800 text-center mb-4">
                                Do you still want to continue ?
                            </p>
                            <div className="flex justify-center">
                                <button
                                    onClick={handleClose}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300"
                                >
                                    Continue
                                </button>
                            </div>
                            <div className="text-base/7 font-medium text-[#591069] mb-2 text-center">
                                OR
                            </div>

                            <div>
                                <ButtonComponent onClick={() => {
                                    _.map(['normal', 'distributor'], (row) => {
                                        setSelectedStore((prv) => ({ ...prv, defaultCode: prv?.newCode }))
                                        localStorageCall().setItem(LOCAL_STORAGE_KEY, 'true')
                                        if (_.includes(pathname, 'main-enrollment')) {
                                            if (UserCountry !== userIpCountry) {
                                                window.location.assign(`/${userIpCountry.toLowerCase()}/main-enrollment`)

                                            } else {

                                                window.location.assign(`/${UserCountry.toLowerCase()}/main-enrollment`)

                                            }
                                        } else {
                                            if (UserCountry !== userIpCountry) {
                                                window.location.assign(`/${userIpCountry.toLowerCase()}`)
                                            } else {
                                                window.location.assign(`/${UserCountry.toLowerCase()}`)


                                            }
                                        }
                                    })
                                }}>
                                    Change The Country  {storeAssign} <ArrowRightAltIcon />  {isUserLogin ? UserCountry : userIpCountry}
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                )}
            </div> */}


        </ >
    );
}

export default withRouteHOC(withMiniCartHOC(Template, 'normal'));